.linline {
    width: 720px;
    float: left;
    display: block;
}

.hiddenrel {
    overflow: hidden;
    position: relative;
}

.translation {
    width: 1600%;
    transition-duration: 0.6s;
    transform: translate3d(-4320px, 0px, 0px);
}

.marginh1 {
    margin-top: -50px;
    margin-bottom: 50px;
    color: #fff;
}

.font60 {
    font-size: 60px;
}

.h1shaddow {
    font-size: 100px;
    letter-spacing: 20px;
    margin-right: -20px;
}

.hero .hero-content {
    padding-top: 5%;
    margin: auto;
}

.hero p.subtitle {
    margin: 0 0;
}

.hero a {
    color: #FFFFFF;
    text-decoration: underline;
}
.blur{
    width: 60%;
    height: 70%;
    /*border: 2px solid;*/
    border-radius: 20px;
    position: absolute;
    top: 22%;
    left: 20%;
    z-index: 0;
    background-color: rgba(35, 39, 49, 0.8);
    /*filter: blur(10px);*/
    /*-webkit-filter: blur(10px);*/
    /*-ms-filter: blur(10px);*/
}

.blur2{
    width: 90%;
    height: 5%;
    border-radius: 10px;
    position: absolute;
    top: 15%;
    left: 5%;
    z-index: 0;
    background-color: rgba(25, 28, 34, 0.8);
}

.alertText {
    display: inline-block;
    vertical-align: middle;
    color: #FFFFFF;
    font-size: 15px;
    padding: 3px 10px;
}
.headerStyle {
    border-bottom: none;
    padding: 0px 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 75px;
}

.subtitle {
    font-size: 30px;
    color: #FFFFFF;
    padding: 0 10px;
}

.subtitle2 {
    font-size: 20px;
    color: #FFFFFF;
    padding: 50px 10px;
}

#about img{
    max-height: 750px;
    width: 100%;
}

.imageCentered {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 10%;
    width: 10%;
}

.learn-more {
    font-size: 13px     !important;
    border: solid 2px   !important;
    border-radius: 40px !important;
    display: inline-block   !important;
    text-transform: uppercase   !important;
    padding: 15px 40px  !important;
    font-weight: 400    !important;
    line-height: 1.42857143 !important;
    text-align: center  !important;
    white-space: nowrap !important;
    vertical-align: middle  !important;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}

.managerEmail {
    color: #acb1b4;
    text-transform: none;
}

.managementClass {
    padding: 80px 0px 20px 0 !important;
}

#managementCarousel a:hover{
    color: white;
}

.carousel-control:focus{
    opacity: .5;
}
.carousel-control:hover{
    opacity: .9 !important;
}

/*.feature-list > p {*/
    /*font-size: 16px !important;*/
/*}*/

.primary-nav > li {
    padding-left: 4px;
}


/* Navbar style ------------------------------*/
.topnav {
    background-color: #232731;
    /*overflow: hidden;*/
}

/* Style the links inside the navigation bar */
.topnav a, .topnav button{
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 13px;
    text-transform: uppercase;
}
.topnav .dropdown {
    float: left;
    display: block;
    padding: 23px 16px;
}

.topnav button.navLinkIcon {
    font-size: 30px;
    transform: translateY(15%);
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
    display: none;
}

/* Dropdown container - needed to position the dropdown content */
/*make it have same characteristics that a.navLink has*/
.dropdown {
    float: left;
    /*overflow: hidden;*/
}

/* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 13px;
    text-transform: uppercase;
    border: none;
    outline: none;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 999;
    margin-top: 47px;
}

/* Style the links inside the dropdown */
.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a dark background on topnav links and the dropdown button on hover */
.topnav a:hover, .topnav button:hover, .dropdown:hover .dropbtn {
    background-color: #2b2f38;
    color: white;
}

/* Add a grey background to dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
    color: black;
}

/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.dropdown:hover .dropdown-content {
    display: block;
}

.topnav .dropdown-content i, .topnav .dropdown-content span{
    display: none;
}

/* When the screen is bigger than 680 pixels wide, make navbar fixed */
@media screen and (min-width: 681px) {
    .topnav {
        position: fixed;
        background-color: #232731;
        z-index: 999;
        width: 100%;
    }
    .navLink {
        transform: translateY(50%);
    }
}

/* When the screen is less than 680 pixels wide, hide all links, except for the first one. Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 680px) {
    .blur {
        width: 80%;
        height: 60%;
        top: 30%;
        left: 10%;
    }
    .blur2 {
        height: 10%;
    }
    .topnav a:not(:first-child), .dropdown .dropbtn {
        display: none;
    }
    .topnav .dropdown {
        padding: 0 0;
    }
    .topnav .dropbtn i{
        display: none;
    }
    .topnav a.icon, .topnav button.icon {
        float: right;
        display: block;
        position: fixed;
        right: 0;
        top: 0;
        background-color: #232731;
        border: none;
        color: #f2f2f2
    }
    .topnav {
        z-index: 999;
        position: fixed;
        width: 100%;
    }
    section.fixed {

    }
    .subtitle2 {
        padding: 20px 10px;
    }
    .hero h1 {
        font-size: 70px;
    }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 680px) {
    .topnav.responsive {
        position: fixed;
    }
    .topnav.responsive a.icon, .topnav.responsive button.icon {
        position: fixed;
        right: 0;
        top: 0;
    }
    .topnav.responsive a, .topnav.responsive button{
        float: none;
        display: block;
        text-align: left;
    }
    .topnav.responsive .dropdown {float: none;}
    .topnav.responsive .dropdown-content {
        position: relative;
        margin-top: 0;
        display: block;
        background-color: #232731;
        box-shadow: none;
    }
    .topnav.responsive .dropdown-content a:hover {
        background-color: #2b2f38;
    }
    .topnav.responsive .dropdown-content a {
        color: #f2f2f2;
    }
    .topnav.responsive .dropdown-content span, .topnav.responsive .dropdown-content i {
        display: contents;
    }
    .topnav.responsive .dropdown .dropbtn {
        display: block;
        width: 100%;
        text-align: left;
    }
    .topnav.responsive .dropdown:hover .dropbtn {
        background-color: #232731;
    }
    .topnav.responsive .dropdown .dropbtn:hover {
        background-color: #2b2f38;
    }
}


.logoNav {
    position: relative;
}

.back {
    z-index: 995;
}
#huskyhuntCarousel img, #votingboothCarousel img, #arcelormittalCarousel img,
#chemstoreCarousel img, #pillowtalkCarousel img, #gvscCarousel img,
#cclcqueueCarousel img, #lbCarousel img, #hidewebsiteCarousel img{
    width: auto;
    height: 400px;
    max-height: 400px;
    margin: auto;
}
@media screen and (max-width: 800px) {
    #huskyhuntCarousel img, #votingboothCarousel img, #arcelormittalCarousel img,
    #chemstoreCarousel img, #pillowtalkCarousel img, #gvscCarousel img,
    #cclcqueueCarousel img, #lbCarousel img, #hidewebsiteCarousel img{
        width: auto;
        height: 250px;
        max-height: 250px;
        margin: auto;
    }
}

.container.sm-margin{
    padding: 0;
}

.center div{
    margin-top: auto;
    margin-bottom: auto;
}

@media screen and (min-width: 800px) {
    .row.flex-vertically{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-right: -25px;
        margin-left: -25px;
    }
}
.color1 section{
    background: #fff
}

.event {
    background: #fff;
    padding: 1rem;
    margin: 1rem;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

section.features-extra.section-padding.color2, #pastprojects {
    background: #F3F4F8;
}
#pastprojects button{
    margin-top: 30px;
    margin-bottom: 30px;
}


/*FAQ*/
.faq-title {
    margin-top: 0px;
    margin-bottom: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: left;
}

.faq-answer {
    text-align: left;
}

/*Social Media Links*/
.row .media-links{
    display:flex;
    align-items: center;
}
#linkedin:hover{
    transition: 0.3s ease;
    fill: #084b8f;
    width: 70px;
    height: 70px;
}
#linkedin{
    transition: 0.3s ease;
    fill: #0a66c2;
}
#github:hover{
    transition: 0.3s ease;
    fill: black;
    width: 70px;
    height: 70px;
}
#github{
    transition: 0.3s ease;
    fill: #353a40;
}
#wwh:hover{
    transition: 0.3s ease;
    width: 80px;
    height: 80px;
}
#wwh{
    transition: 0.3s ease;
    width: 60px;
    height: 60px;
}
